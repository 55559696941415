import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core/";

import { Link } from "react-router-dom";

import PageWrapper from "../components/PageWrapper.js";

function Home() {
  const [studyChosen, setStudyChosen] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("instrument.id") && localStorage.getItem("grade")) {
      setStudyChosen(true);
    }
  }, []);

  return (
    <PageWrapper>
      <Box className="centerContent">
        <Link to={studyChosen ? "/study" : "/register"}>
          <Button variant="contained" color="primary" className="button">
            Start
          </Button>
        </Link>
        <Typography variant="h6">
          We'll help you pass your music grades, free!
        </Typography>
      </Box>
    </PageWrapper>
  );
}

export default Home;
